import React from "react"

/** Auxiliaries */
import { Container, Flex, Text, Title } from "../../auxiliaries"

/** Utils */
import { GRAY_650 } from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useLang } from "../../../utils/layout/LanguageContext"

/** Components */
import Item from "./Item"

/** Locales */
import locales from "./locales/Products.json"

const Products = () => {
  const device = useDeviceType()
  const lang = useLang()

  if (!device) return null

  const data = [
    // {
    //   link: "/vps",
    //   title: locales[lang]["VPS"]["TITLE"],
    //   text: locales[lang]["VPS"]["DESCRIPTION"],
    //   icon: {
    //     name: "joystick",

    //     width: device === "mobile" ? "27.347342995vw" : "21.631944444vw",
    //     height: device === "mobile" ? "38.164251208vw" : "30.104166667vw",
    //   },
    // },
    {
      link: "/baremetal",
      title: locales[lang]["BAREMETAL"]["TITLE"],
      text: locales[lang]["BAREMETAL"]["DESCRIPTION"],
      icon: {
        name: "dedicated",
        width: device === "mobile" ? "33.816425121vw" : "16.006944444vw",
        height: device === "mobile" ? "38.164251208vw" : "30.104166667vw",
      },
    },
    {
      link: "/colocation",
      title: locales[lang]["COLOCATION"]["TITLE"],
      text: locales[lang]["COLOCATION"]["DESCRIPTION"],
      icon: {
        name: "cloud",
        width: device === "mobile" ? "31.647342995vw" : "25.381944444vw",
        height: device === "mobile" ? "38.164251208vw" : "30.104166667vw",
      },
    },
  ]

  return (
    <Container
      id="pricing"
      delimiter={device === "mobile" && "80.676328502vw"}
      direction="column"
      alignItems={device === "mobile" ? "flex-start" : "center"}
      gap={device === "mobile" ? "15.458937198vw" : "4.166666667vw"}
      mx="auto"
      my={device === "mobile" ? "28.985507246vw" : "11.111111111vw"}
    >
      <Flex
        direction="column"
        gap={device === "mobile" ? "5.797101449vw" : "2.222222222vw"}
      >
        <Title
          fontFamily="Raleway"
          fontSize={device === "mobile" ? "9.661835749vw" : "4em"}
          weight="black"
          textAlign={device !== "mobile" && "center"}
          maxWidth={device === "mobile" ? "78.260869565vw" : "49.097222222vw"}
        >
          {locales[lang]["TITLE"]}{" "}
        </Title>
        <Text
          fontSize={device === "mobile" ? "3.8647343vw" : "1.111111111vw"}
          textAlign={device !== "mobile" && "center"}
          color={GRAY_650}
          maxWidth={device === "mobile" && "73.913043478vw"}
        >
          {locales[lang]["DESCRIPTION"]}
        </Text>
      </Flex>
      <Flex
        direction="column"
        gap={device === "mobile" ? "9.6618vw" : "2.777777778vw"}
      >
        {data.map((item, index) => (
          <Item {...item} key={index} lang={lang} />
        ))}
      </Flex>
    </Container>
  )
}

export default Products
