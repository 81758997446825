import React, { useEffect, useRef, useState } from "react"
import CountUp from "react-countup"
import styled from "styled-components"

/** Auxiliaries */
import { Flex, Text } from "../../auxiliaries"

/** Utils */
import {
  CLEAR_100,
  DARK_900,
  YELLOW_100,
  YELLOW_500,
} from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useScrollPosition } from "../../../utils/layout/ScrollContext"

const Item = ({ preText, subject, amount }) => {
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const ref = useRef()
  const device = useDeviceType()
  const scrollY = useScrollPosition()

  useEffect(() => {
    if (ref && ref.current) {
      if (scrollY > ref.current.scrollTop - 450) {
        setShouldAnimate(true)
      }
    }
  }, [scrollY])

  if (!device) return null

  return (
    <Flex ref={ref} direction="column" gap={device === "mobile" && "3.8647vw"}>
      <Text
        fontFamily="Raleway"
        fontSize={device === "mobile" ? "5.7971vw" : "1.388888889vw"}
        weight="black"
        color={CLEAR_100}
        ml={device !== "mobile" && "1.388888889vw"}
      >
        {preText}
      </Text>
      <AmountWrapper
        device={device}
        bgColor={YELLOW_500}
        padding={device === "mobile" ? "3.8647vw" : "1.388888889vw"}
      >
        <Text
          fontSize={device === "mobile" ? "19.323671498vw" : "4.944444vw"}
          weight="black"
          color={YELLOW_100}
        >
          {shouldAnimate ? <CountUp end={amount} duration={8} /> : 0}
        </Text>
      </AmountWrapper>
      <Text
        fontFamily="Raleway"
        fontSize={device === "mobile" ? "5.7971vw" : "1.388888889vw"}
        weight="black"
        color={CLEAR_100}
        ml={device !== "mobile" && "1.388888889vw"}
      >
        {subject}
      </Text>
    </Flex>
  )
}

const AmountWrapper = styled(Flex)`
  position: relative;
  width: fit-content;

  &:after {
    content: "";

    position: absolute;
    bottom: ${({ device }) =>
    device === "mobile" ? "-2.415458937vw" : "-0.347222222vw"};
    right: 0px;
    width: 8.819444444vw;
    height: ${({ device }) =>
    device === "mobile" ? "2.415458937vw" : "0.347222222vw"};
    background-color: ${DARK_900};
  }

  &:before {
    content: "";

    position: absolute;
    bottom: ${({ device }) =>
    device === "mobile" ? "-2.415458937vw" : "-0.347222222vw"};
    right: ${({ device }) =>
    device === "mobile" ? "-2.415458937vw" : "-0.347222222vw"};
    width: ${({ device }) =>
    device === "mobile" ? "2.415458937vw" : "0.347222222vw"};
    height: ${({ device }) =>
    device === "mobile"
      ? "calc(100% + 2.415458937vw)"
      : "calc(100% + 0.347222222vw)"};
    background-color: ${DARK_900};
  }
`

export default Item
