import React from "react"

/** Auxiliaries */
import { Container, Flex, Text, Title } from "../../auxiliaries"

/** Utils */
import { GRAY_650 } from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useLang } from "../../../utils/layout/LanguageContext"

/** Components */
import Item from "./Item"

/** Locales */
import locales from "./locales/Advantages.json"

const Advantages = () => {
  const device = useDeviceType()
  const lang = useLang()

  if (!device) return null

  const items = [
    {
      title: locales[lang]["CARDS"]["FIRST"]["TITLE"],
      description: locales[lang]["CARDS"]["FIRST"]["DESCRIPTION"],
      icons: [
        {
          name: "medal",
          top: "-6.125vw",
          left: "-7.145833333vw",
          height: "20.416666667vw",
          width: "14.548611111vw",
        },
        {
          name: "money",
          top: "4.0625vw",
          right: "-26.711806vw",
          height: "20.729166667vw",
          width: "38.715277778vw",
          style: { zIndex: "-1" },
        },
      ],
    },
    {
      title: locales[lang]["CARDS"]["SECOND"]["TITLE"],
      description: locales[lang]["CARDS"]["SECOND"]["DESCRIPTION"],
      icons: [
        {
          name: "hand",
          top: "-6.125vw",
          left: "-7.145833333vw",
          height: "20.416666667vw",
          width: "14.548611111vw",
        },
        {
          name: "man",
          top: "3.0625vw",
          right: "-23.711806vw",
          height: "20.729166667vw",
          width: "38.715277778vw",
          style: { zIndex: "1" },
        },
      ],
    },
    {
      title: locales[lang]["CARDS"]["THIRD"]["TITLE"],
      description: locales[lang]["CARDS"]["THIRD"]["DESCRIPTION"],
      icons: [
        {
          name: "padlock",
          top: "-8.800vw",
          left: "-7.145833333vw",
          height: "15.416666667vw",
          width: "14.548611111vw",
        },
        {
          name: "server-eggs",
          top: "4.0625vw",
          right: "-24.291806vw",
          height: "20.729166667vw",
          width: "38.715277778vw",
          style: { zIndex: "-1" },
        },
      ],
    },
    {
      title: locales[lang]["CARDS"]["FOURTH"]["TITLE"],
      description: locales[lang]["CARDS"]["FOURTH"]["DESCRIPTION"],
      icons: [
        {
          name: "on",
          top: "-7.800vw",
          left: "-3.145833333vw",
          height: "15.416666667vw",
          width: "14.548611111vw",
        },
        {
          name: "speedometer",
          top: "4.0625vw",
          right: "-28.511806vw",
          height: "20.729166667vw",
          width: "38.715277778vw",
          style: { zIndex: "-1" },
        },
      ],
    },
    {
      title: locales[lang]["CARDS"]["FIFTH"]["TITLE"],
      description: locales[lang]["CARDS"]["FIFTH"]["DESCRIPTION"],
      icons: [
        {
          name: "hardware",
          top: "-7.800vw",
          left: "-7.145833333vw",
          height: "18.416666667vw",
          width: "14.548611111vw",
        },
        {
          name: "gear",
          top: "4.0625vw",
          right: "-29.211806vw",
          height: "20.729166667vw",
          width: "38.715277778vw",
          style: { zIndex: "-1" },
        },
      ],
    },
    {
      title: locales[lang]["CARDS"]["SIXTH"]["TITLE"],
      description: locales[lang]["CARDS"]["SIXTH"]["DESCRIPTION"],
      icons: [
        {
          name: "diamond",
          top: "-5.800vw",
          left: "-7.145833333vw",
          height: "16.416666667vw",
          width: "14.548611111vw",
        },
        {
          name: "plug",
          top: "4.0625vw",
          right: "-27.911806vw",
          height: "20.729166667vw",
          width: "38.715277778vw",
          style: { zIndex: "-1" },
        },
      ],
    },
    {
      title: locales[lang]["CARDS"]["SEVENTH"]["TITLE"],
      description: locales[lang]["CARDS"]["SEVENTH"]["DESCRIPTION"],
      icons: [
        {
          name: "shield",
          top: "-5.800vw",
          left: "-7.145833333vw",
          height: "16.416666667vw",
          width: "14.548611111vw",
        },
        {
          name: "arrow",
          top: "4.0625vw",
          right: "-28.411806vw",
          height: "23.729167vw",
          width: "38.715277778vw",
          style: { zIndex: "-1" },
        },
      ],
    },
    {
      title: locales[lang]["CARDS"]["EIGHTH"]["TITLE"],
      description: locales[lang]["CARDS"]["EIGHTH"]["DESCRIPTION"],
      icons: [
        {
          name: "check",
          top: "-7.800vw",
          left: "-7.145833333vw",
          height: "16.416666667vw",
          width: "14.548611111vw",
        },
        {
          name: "balance",
          top: "4.0625vw",
          right: "-26.11806vw",
          height: "20.729166667vw",
          width: "38.715277778vw",
          style: { zIndex: "-1" },
        },
      ],
    },
  ]

  return (
    <Container
      id="advantages"
      delimiter={device === "mobile" && "80.676328502vw"}
      direction="column"
      alignItems={device !== "mobile" && "center"}
      gap={device === "mobile" ? "13.526570048vw" : "4.166666667vw"}
      mx="auto"
      mt={device === "mobile" ? "50.241545894vw" : "100px"}
    >
      <Flex
        direction="column"
        gap={device === "mobile" ? "5.797101449vw" : "2.222222222vw"}
        mb="7.5vw"
      >
        <Title
          fontFamily="Raleway"
          fontSize={device === "mobile" ? "9.661835749vw" : "4em"}
          weight="black"
          textAlign={device !== "mobile" && "center"}
        >
          {locales[lang]["TITLE"]}
        </Title>
        <Text
          fontSize={device === "mobile" ? "3.8647343vw" : "1.111111111vw"}
          textAlign={device !== "mobile" && "center"}
          color={GRAY_650}
        >
          {locales[lang]["DESCRIPTION"]}
        </Text>
      </Flex>
      <Item items={items} />
    </Container>
  )
}

export default Advantages
