import React from "react"

/** Auxiliaries */
import { Container, Icon, Text, Title } from "../../auxiliaries"

/** Utils */
import { GRAY_650 } from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useLang } from "../../../utils/layout/LanguageContext"

/** Locales */
import locales from "./locales/Landing.json"

const Landing = () => {
  const device = useDeviceType()
  const lang = useLang()

  if (!device) return null

  return (
    <Container
      delimiter={device === "mobile" ? "80.676328502vw" : "86.111111111vw"}
      direction={device === "mobile" ? "column" : "row"}
      ml="auto"
      mr={device === "mobile" && "auto"}
      mt="50px"
      alignItems={device === "mobile" ? "flex-start" : "center"}
      gap={device === "mobile" ? "15.458937198vw" : "5.069444444vw"}
    >
      <Container
        delimiter={device === "mobile" ? "73.913043478vw" : "30.416666667vw"}
        direction="column"
        gap={device === "mobile" ? "5.797101449vw" : "2,222222222vw"}
      >
        <Title
          fontFamily="Raleway"
          fontSize={device === "mobile" ? "9.661835749vw" : "4.513888889vw"}
          weight="black"
        >
          {locales[lang]["TITLE"]}
        </Title>
        <Text
          fontSize={device === "mobile" ? "3.8647343vw" : "1.111111111vw"}
          color={GRAY_650}
        >
          {locales[lang]["DESCRIPTION"]}
        </Text>
      </Container>
      <Icon
        name="secure"
        height={device === "mobile" ? "58.45410628vw" : "36.875vw"}
        width={device === "mobile" ? "79.951690821vw" : "50.555555556vw"}
      />
    </Container>
  )
}

export default Landing
