import React, { useEffect, useMemo, useRef, useState } from "react"
import styled from "styled-components"

/** Auxiliaries */
import { Flex, Title, Container } from "../../auxiliaries"

/** Utils */
import {
  PURPLE_300,
  PURPLE_500,
  PURPLE_700,
} from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useLang } from "../../../utils/layout/LanguageContext"
import { useScrollPosition } from "../../../utils/layout/ScrollContext"

/** Components */
import UserReview from "./UserReview"

/** Locales */
import locales from "./locales/Review.json"

const colors = [PURPLE_500, PURPLE_700, PURPLE_300]

const remapCards = list =>
  list.map(subList =>
    subList.map(item => ({
      ...item,
      color: colors[Math.floor(Math.random() * 10) % 3],
    }))
  )

const Review = () => {
  const device = useDeviceType()
  const lang = useLang()
  const scrollY = useScrollPosition()

  const item1 = locales[lang]["ITEMS"][0]
  const item2 = locales[lang]["ITEMS"][1]
  const item3 = locales[lang]["ITEMS"][2]
  const item4 = locales[lang]["ITEMS"][3]
  const item5 = locales[lang]["ITEMS"][4]
  const item6 = locales[lang]["ITEMS"][5]
  const cardsList = [
    [{ ...item1 }, { ...item2 }],
    [{ ...item3 }, { ...item4 }],
    [{ ...item5 }, { ...item6 }],
  ]

  const [oddMarginLeft, setOddMarginLeft] = useState({
    desktop: "11.25vw",
    mobile: "-57.004830918vw",
  })
  const [evenMarginLeft, setEvenMarginLeft] = useState({
    desktop: "0vw",
    mobile: "0vw",
  })
  const cards = useMemo(() => remapCards(cardsList), [lang])
  const ref = useRef()

  useEffect(() => {
    if (ref && ref.current) {
      const compareTo =
        device === "mobile"
          ? ref.current.offsetTop
          : ref.current.offsetTop - 450

      if (
        scrollY > compareTo &&
        scrollY < compareTo + ref.current.offsetHeight
      ) {
        if (device === "mobile") {
          let scrollOffset =
            1 - (scrollY - ref.current.offsetTop) / ref.current.offsetHeight

          if (scrollOffset > 1) {
            scrollOffset = 1
          }

          setOddMarginLeft(o => ({
            ...o,
            mobile: `-${57.004830918 * scrollOffset}vw`,
          }))

          setEvenMarginLeft(o => ({
            ...o,
            mobile: `-${57.004830918 - 57.004830918 * scrollOffset}vw`,
          }))
        } else {
          let scrollOffset =
            1 -
            (scrollY - (ref.current.offsetTop - 450)) / ref.current.offsetHeight

          if (scrollOffset > 1) {
            scrollOffset = 1
          }

          setOddMarginLeft(o => ({
            ...o,
            desktop: `${11.25 * scrollOffset}vw`,
          }))

          setEvenMarginLeft(o => ({
            ...o,
            desktop: `${11.25 - 11.25 * scrollOffset}vw`,
          }))
        }
      }
    }
  }, [ref, scrollY, device])

  if (!device) return null

  return (
    <Container
      ref={ref}
      delimiter={device === "mobile" ? "100vw" : "86.111111111vw"}
      direction={device === "mobile" ? "column" : "row"}
      ml={device !== "mobile" && "auto"}
      mt={device === "mobile" ? "50.2415vw" : "13.194444444vw"}
      gap={device === "mobile" ? "15.4589vw" : "8.541666667vw"}
    >
      <Title
        fontFamily="Raleway"
        fontSize={device === "mobile" ? "9.6618vw" : "4em"}
        weight="black"
        mt={device !== "mobile" && "6.944444444vw"}
        ml={device === "mobile" && "9.6618vw"}
        maxWidth={device === "mobile" && "72.463768116vw"}
      >
        {locales[lang]["TITLE"]}
      </Title>
      <OverflowContainer
        delimiter={device === "mobile" ? "100vw" : "39.583333333vw"}
        direction="column"
        gap={device === "mobile" ? "3.8647vw" : "2.256944444vw"}
      >
        {cards.map((items, i) => (
          <Flex
            key={i}
            gap={device === "mobile" ? "3.8647vw" : "2.256944444vw"}
            width={device === "mobile" ? "100vw" : "39.583333333vw"}
            ml={
              i % 2 !== 0
                ? device === "mobile"
                  ? oddMarginLeft.mobile
                  : oddMarginLeft.desktop
                : device === "mobile"
                  ? evenMarginLeft.mobile
                  : evenMarginLeft.desktop
            }
          >
            {items.map((item, index) => (
              <UserReview key={`${i}-${index}`} {...item} />
            ))}
          </Flex>
        ))}
      </OverflowContainer>
    </Container>
  )
}

const OverflowContainer = styled(Container)`
  overflow: hidden;
`

export default Review
