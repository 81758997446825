import React from "react"

/** Auxiliaries */
import { Container, Icon, Text, Title } from "../../auxiliaries"

/** Utils */
import { GRAY_650 } from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useLang } from "../../../utils/layout/LanguageContext"

/** Locales */
import locales from './locales/Location.json'

const Location = () => {
  const device = useDeviceType()
  const lang = useLang()

  if (!device) return null

  return (
    <Container
      delimiter={device === "mobile" ? "80.676328502vw" : "75.902777778vw"}
      direction={device === "mobile" ? "column" : "row"}
      gap="11.111111111vw"
      mx="auto"
      mt={device === "mobile" ? "50.2415vw" : "12.361111111vw"}
      alignItems={device !== "mobile" && "center"}
    >
      <Container
        delimiter={device === "mobile" ? "73.913043478vw" : "26.111111111vw"}
        direction="column"
        gap={device === "mobile" ? "5.797101449vw" : "2,222222222vw"}
      >
        <Title
          fontFamily="Raleway"
          fontSize={device === "mobile" ? "9.661835749vw" : "4em"}
          weight="black"
          maxWidth={device === "mobile" && "53.140096618vw"}
        >
          {locales[lang]['TITLE']}
        </Title>
        <Text
          fontSize={device === "mobile" ? "3.8647343vw" : "1.111111111vw"}
          color={GRAY_650}
        >
          {locales[lang]['DESCRIPTION']}
        </Text>
      </Container>
      <Icon
        name="map"
        height={device === "mobile" ? "70.048309179vw" : "24.7639vw"}
        width={device === "mobile" ? "80.676328502vw" : "38.611111111vw"}
      />
    </Container>
  )
}

export default Location
