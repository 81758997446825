import React from "react"

/** Auxiliaries */
import { Flex, Text } from "../../auxiliaries"

/** Utils */
import { CLEAR_100 } from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"

const UserReview = ({ color, text, user }) => {
  const device = useDeviceType()

  if (!device) return null

  return (
    <Flex
      bgColor={color}
      height={device === "mobile" ? "34.299516908vw" : "7.083333333vw"}
      width={device === "mobile" ? "80.6763vw" : "18.75vw"}
      minWidth={device === "mobile" && "80.6763vw"}
      px={device === "mobile" ? "5.797101449vw" : "1.527777778vw"}
      py={device === "mobile" ? "7.729468599vw" : "1.666666667vw"}
      gap={device === "mobile" ? "7.729468599vw" : "0.625vw"}
    >
      <Text
        fontFamily="Raleway"
        fontSize={device === "mobile" ? "28.985507246vw" : "5.555555556vw"}
        lineHeight="0.5"
        color={CLEAR_100}
      >
        “
      </Text>
      <Flex
        direction="column"
        gap={device === "mobile" ? "3.8647343vw" : "0.416666667vw"}
      >
        <Text
          fontFamily="Raleway"
          fontSize={device === "mobile" ? "2.8986vw" : "12px"}
          color={CLEAR_100}
        >
          {text}
        </Text>
        <Text
          fontFamily="Raleway"
          fontSize={device === "mobile" ? "2.8986vw" : "0.625vw"}
          weight="bold"
          color={CLEAR_100}
        >
          ~ {user}
        </Text>
      </Flex>
    </Flex>
  )
}

export default UserReview
