import React from "react"

/** Auxiliaries */
import {
  Button,
  Container,
  Flex,
  Icon,
  Link,
  Text,
  Title,
} from "../../auxiliaries"

/** Utils */
import { CLEAR_100, PURPLE_700 } from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"

/** Locales */
import locales from "./locales/Products.json"

const Item = ({ lang = "PT", link, title, text, icon }) => {
  const device = useDeviceType()

  if (!device) return null

  const markup = (
    <Flex
      bgColor={PURPLE_700}
      width={device === "mobile" ? "80.676328502vw" : "56.145833333vw"}
      pt={device === "mobile" ? "9.6618vw" : "8.333333333vw"}
      pl={device === "mobile" ? "9.6618vw" : "4.166666667vw"}
      pb={device === "mobile" ? "9.6618vw" : "4.166666667vw"}
      position="relative"
    >
      <Icon {...icon} position="absolute" right="0" bottom="0" />
      <Container delimiter="32.395833333vw" direction="column">
        <Title
          fontFamily="Raleway"
          fontSize={device === "mobile" ? "3.8647vw" : "80px"}
          weight="black"
          color={CLEAR_100}
        >
          {title}
        </Title>
        <Text
          fontSize={device === "mobile" ? "2.898550725vw" : "1.111111111vw"}
          color={CLEAR_100}
          mt={device === "mobile" ? "3.8647vw" : "0.833333333vw"}
          mb={device !== "mobile" && "2.777777778vw"}
        >
          {text}
        </Text>
        {device !== "mobile" && link && (
          <Flex>
            <Link href={link}>
              <Button bgColor={CLEAR_100}>
                <Text fontSize="16px" color={PURPLE_700}>
                  {locales[lang]["SEE_MORE_PLANS"]}
                </Text>
              </Button>
            </Link>
          </Flex>
        )}
      </Container>
    </Flex>
  )

  if (link && device === "mobile") {
    return <Link href={link}>{markup}</Link>
  }

  return markup
}

export default Item
