import React from "react"
import styled from "styled-components"

/** Auxiliaries */
import { Container, Flex, Text, Title } from "../../auxiliaries"

/** Utils */
import {
  BLUE_500,
  CLEAR_100,
  DARK_900,
  PURPLE_700,
} from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"
import { useLang } from "../../../utils/layout/LanguageContext"

/** Components */
import Item from "./Item"

/** Locales */
import locales from "./locales/Statistics.json"

const Statistics = () => {
  const device = useDeviceType()
  const lang = useLang()

  if (!device) return null

  return (
    <Wrapper
      device={device}
      bgColor={PURPLE_700}
      mt={device === "mobile" ? "28.985507246vw" : "100px"}
      py={device === "mobile" ? "38.647342995vw" : "50px"}
    >
      <Container
        direction={device === "mobile" ? "column-reverse" : "row"}
        delimiter={device === "mobile" ? "80.676328502vw" : "65.972222222vw"}
        gap={device === "mobile" ? "15.4589vw" : "8.125vw"}
        alignItems={device !== "mobile" && "center"}
        mx="auto"
      >
        <Flex direction="column" gap="6.083333vw">
          <Item
            preText={locales[lang]["MORE_THAN"]}
            amount={10000}
            subject={locales[lang]["CUSTOMERS"]}
          />
          <Item
            preText={locales[lang]["OVER"]}
            amount={20000}
            subject={locales[lang]["SERVERS_CREATED"]}
          />
        </Flex>
        <Title
          fontFamily="Raleway"
          fontSize={device === "mobile" ? "9.6618vw" : "5em"}
          weight="black"
          color={CLEAR_100}
        >
          {locales[lang]["TITLE"][0]}{" "}
          <Text
            fontFamily="Raleway"
            fontSize={device === "mobile" ? "9.6618vw" : "1em"}
            weight="black"
            color={BLUE_500}
            style={{ display: "inline" }}
          >
            {locales[lang]["TITLE"][1]}
          </Text>
        </Title>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled(Flex)`
  border-bottom: ${({ device }) =>
    device === "mobile" && `2.415458937vw solid ${DARK_900}`};
`

export default Statistics
