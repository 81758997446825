import React from "react"
import { Helmet } from "react-helmet"

/** Assets */
import GlobalStyle from "../assets/styled/global"

/** Utils */
import { DeviceTypeProvider } from "../utils/layout/LayoutContext"
import { LangProvider } from "../utils/layout/LanguageContext"
import { ScrollPositionProvider } from "../utils/layout/ScrollContext"

/** Shared components */
import Footer from "../components/Shared/Footer"
import Help from "../components/Shared/Help"
import Navbar from "../components/Shared/Navbar"

/** Components */
import Advantages from "../components/Home/Advantages"
import Landing from "../components/Home/Landing"
import Location from "../components/Home/Location"
import Products from "../components/Home/Products"
import Review from "../components/Home/Review"
import Statistics from "../components/Home/Statistics"

export default function Home({ location }) {
  return (
    <ScrollPositionProvider>
      <DeviceTypeProvider>
        <LangProvider location={location}>
          <Helmet>
            <title>Melhores soluções em Datacenter | ENX Host</title>
            <meta
              content="As melhores soluções para hospedagem na nuvem. Qualidade garantida ou seu dinheiro de volta nos primeiros 7 dias."
              name="description"
            />
            <meta
              content="Melhores soluções em Datacenter | ENX Host"
              property="og:title"
            />
            <meta
              content="As melhores soluções para hospedagem na nuvem. Qualidade garantida ou seu dinheiro de volta nos primeiros 7 dias."
              property="og:description"
            />
            <meta
              content="Melhores soluções em hospedagem | ENX Host"
              property="twitter:title"
            />
            <meta
              content="As melhores soluções para hospedagem na nuvem. Qualidade garantida ou seu dinheiro de volta nos primeiros 7 dias."
              property="twitter:description"
            />
            <meta property="og:type" content="website" />
            <meta content="summary_large_image" name="twitter:card" />
            <meta
              content="width=device-width, initial-scale=1"
              name="viewport"
            />
            <link
              href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;800;900&family=Raleway:wght@300;500;900&display=swap"
              rel="stylesheet"
            />
          </Helmet>
          <GlobalStyle />
          <Navbar />
          <Landing />
          <Advantages />
          <Location />
          <Review />
          <Statistics />
          <Products />
          <Help />
          <Footer />
        </LangProvider>
      </DeviceTypeProvider>
    </ScrollPositionProvider>
  )
}
