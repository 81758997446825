import React, { useEffect, useState } from "react"
import styled, { css } from "styled-components"

/** Auxiliaries */
import { Flex, Icon, Text, Title } from "../../auxiliaries"
import { SlideDown } from "../../auxiliaries/Mixins"

/** Utils */
import { PURPLE_50, PURPLE_100, PURPLE_300 } from "../../../utils/layout/COLORS"
import { useDeviceType } from "../../../utils/layout/LayoutContext"

const Item = ({ items }) => {
  const [hasPrevAndNext, setHasPrevAndNext] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)
  const [shouldAnimate, setShouldAnimate] = useState(false)
  const [next, setNext] = useState()
  const [active, setActive] = useState(0)
  const device = useDeviceType()

  const rest = (items.length - 1) % 3

  const goTo = index => {
    if (items.length > 1) {
      setShouldAnimate(true)
    }

    if (index === -1) {
      return setNext(items.length - 1)
    }

    if (index >= items.length) {
      return setNext(0)
    }

    return setNext(index)
  }

  useEffect(() => {
    if (rest > 0 && !hasPrevAndNext) {
      setHasPrevAndNext(rest % 2 === 0)
    }
  }, [rest])

  useEffect(() => {
    let timeout

    if (shouldAnimate && !isAnimating) {
      setIsAnimating(true)

      timeout = setTimeout(() => {
        setActive(next)
        setShouldAnimate(false)
        setIsAnimating(false)
      }, 500)
    }

    return () => timeout && clearTimeout(timeout)
  }, [shouldAnimate])

  if (!device) return null

  return [items[active]].map(({ title, description, icons }) => (
    <CardContainer
      key={title}
      shouldAnimate={isAnimating}
      amountOfCards={rest}
      height={device === "mobile" ? "58.4541vw" : "27.777777778vw"}
      width={device === "mobile" ? "80.676328502vw" : "49.861111111vw"}
      position="relative"
    >
      <CardWrapper
        shouldAnimate={isAnimating}
        direction="column"
        gap={device === "mobile" ? "3.8647343vw" : "2.083333333vw"}
        bgColor={PURPLE_300}
        height={device === "mobile" ? "58.4541vw" : "27.777777778vw"}
        width={device === "mobile" ? "80.676328502vw" : "49.861111111vw"}
        px={device === "mobile" ? "5.072463768vw" : "3.125vw"}
        py={device === "mobile" ? "4.830917874vw" : "2.326388889vw"}
        position="relative"
      >
        {device !== "mobile" && (
          <>
            {icons.map(icon => (
              <Icon
                key={icon.name}
                position="absolute"
                {...{ ...icon }}
              />
            )
            )}
          </>
        )}
        <Flex
          direction="column"
          gap={device === "mobile" ? "3.8647343vw" : "2.222222222vw"}
          px={device === "mobile" ? "4.589371981vw" : "3.125vw"}
          pt={device === "mobile" ? "4.830917874vw" : "3.229166667vw"}
        >
          <Title
            fontFamily="Raleway"
            fontSize={device === "mobile" ? "5.797101449vw" : "4.166666667vw"}
            weight="black"
            minHeight={"9.7916667vw"}
            dangerouslySetInnerHTML={{ __html: title }}
          />
          <Text
            fontSize={device === "mobile" ? "2.898550725vw" : "1.111111111vw"}
            minHeight={"4.53125vw"}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </Flex>
        <Flex justifyContent="flex-end" gap="1.666666667vw">
          {device !== "mobile" && (
            <Icon
              name="arrow-up"
              width="1.388888889vw"
              height="1.458333333vw"
              onClick={() => goTo(active - 1)}
            />
          )}
          <Icon
            name="arrow-down"
            width={device !== "mobile" ? "1.388888889vw" : "3.480676329vw"}
            height={device !== "mobile" ? "1.458333333vw" : "3.239130435vw"}
            onClick={() => goTo(active + 1)}
          />
        </Flex>
      </CardWrapper>
    </CardContainer>
  ))
}

const CardContainer = styled(Flex)`
  ${({ amountOfCards, shouldAnimate }) =>
    amountOfCards > 0 &&
    css`
      &::before {
        content: "";
        position: absolute;
        left: 10%;
        top: -10%;
        width: 80%;
        height: 80%;
        background-color: ${PURPLE_100};
        z-index: -1;
        transition: all 0.7s;

        ${shouldAnimate &&
      css`
          background-color: ${PURPLE_300};
          transform: translate(0%, 25%) scale(1.25);
        `}
      }
    `}

  ${({ amountOfCards, shouldAnimate }) =>
    amountOfCards > 1 &&
    css`
      &::after {
        content: "";
        position: absolute;
        left: 20%;
        top: -20%;
        width: 60%;
        height: 60%;
        background-color: ${PURPLE_50};
        z-index: -2;
        transition: all 0.7s;

        ${shouldAnimate &&
      css`
          background-color: ${PURPLE_100};
          transform: translate(0%, 33%) scale(1.33);
        `}
      }
    `}
`

const CardWrapper = styled(Flex)`
  ${({ shouldAnimate }) =>
    !!shouldAnimate &&
    css`
      animation: 0.7s ${SlideDown} ease-in;
    `}
`

export default Item
